<modal-open-point id="confirmar-checkout-modal-1">
  <div normal>
    <confirmar-checkout-modal></confirmar-checkout-modal>
  </div>
</modal-open-point>
<div *ngIf="modo==='normal'" style="zoom:120%">
  <div class="container">
    <div class="productos">
      <div class="productoslabel">
        Productos ({{articulosconcantidad.length}})
      </div>
      <div class="listaprod">
        <lista-productos-view [articulosIn]="articulosconcantidad" [modelo]="'postcheckout'">
        </lista-productos-view>
      </div>
    </div>

    <div class="checkoutcontainer">
      <div class="informacionPersonal">
        <div class="productoslabel">
          Información Personal
        </div>
        <div class="infobox">
          <div class="info" *ngIf="user != undefined">
            {{user.nombre}}
          </div>
          <div class="infoEmail" *ngIf="user != undefined">
            {{user.email}}
          </div>
          <div class="info" *ngIf="user != undefined">
            {{user.telefonos}}
          </div>
        </div>
      </div>
      <div class="envioOretiro" >
        <div class="productoslabel">
          Envio o Retiro?
        </div>
        <div class="infobox">
          <div class="roundedOneE" id="envioHab" name="check" [ngStyle]="{'display': envioHab}"
            style="flex-direction: row; display: flex; align-items: center; margin: 20px; margin-left: 30px;">
            <input (click)="envio(); setRetiroEnvio(1)" type="checkbox" id="displayEnvio" class="checkboxes" />
            <label style="margin-left: 20px; font-size: 22px;" for="displayEnvio">
              Envio
            </label>
          </div>
          <div class="roundedOneR" id="retiroHab" name="check"
            style="flex-direction: row; display: flex; align-items: center; margin: 20px; margin-left: 30px;">
            <input (click)="retiro(); setRetiroEnvio(0)" type="checkbox" id="displayRetiro" class="checkboxes" />
            <label style="margin-left: 20px; font-size: 22px;" for="displayRetiro">
              Retiro
            </label>
          </div>
        </div>
      </div>

      <div class="envioContainer" [ngStyle]="{'display': displayEnvio ? 'flex': 'none'}">
        <div>
          <div class="productoslabel separator" (click)="abrirCerrar">
            Envio a Domicilio
          </div>
          <div class="infobox">
            <app-envios-form *ngIf="displayEnvio" (envioSeleccionado)="seEligioDatosEnvio($event)" ></app-envios-form>
          </div>
        </div>
        <div *ngIf="usaTurnos == 'S' && envioHabilitado == 'S'; else noUsaTurnos">
          <div class="productoslabel separator ">
            Elegí fecha y hora de entrega
          </div>
          <mmax-turnos-reparto (turnoSelecionado)="turnoselect($event)"></mmax-turnos-reparto>
        </div>
        <div>
          <div class="productoslabel separator">
            Quién recibe el pedido?
          </div>
          <div class="infobox">
            <div class="info infoblue" *ngIf="user != undefined">
              {{user.nombre}}
            </div>
          </div>
        </div>
        <div>
          <div class="productoslabel">
            Observaciones
          </div>
          <div class="boxtext">
            <input (change)="observaCion()" id="obs" class="boxobs" placeholder="Escribe aquí las aclaraciones sobre tu pedido…">
          </div>
        </div>

        <div *ngIf="costoEnvio" class="subTotal">
          Subtotal: {{totales - costoEnvio | currency}} <br>
          Envio: {{costoEnvio | currency}}
        </div>

        <div class="total">
          Total: {{totales | currency}}
        </div>
        <div class="botncontainer">
          <div (click)="iraLand()" onclick="window.scrollTo(0,0)" class="noselect btons conticompra">
            Anterior
          </div>
          <button [disabled]="usaTurnos == 'S' && turnoselecionado==undefined" (click)="modalCheckout()"
            onclick="window.scrollTo(0,0)" class="noselect buttonpc blubutton">
            Siguiente
          </button>
        </div>
      </div>
      <div class="retiroContainer" [ngStyle]="{'display': displayRetiro ? 'flex' : 'none'}">
        <div>
          <div class="productoslabel separator">
            Sucursal de Retiro
          </div>
          <div class="infobox" *ngIf="sucusarl != undefined && sucTrue == true; else NotSuc">
            <div class="info infoblue">
              {{sucusarl.nombre}} - {{sucusarl.barrio}}
            </div>
            <div class="info">
              {{sucusarl.calle}} - {{sucusarl.localidad}}
            </div>
          </div>
        </div>

        <div *ngIf="usaTurnos == 'S'; else noUsaTurnos">
          <div class="productoslabel separator ">
            Elegí fecha y hora de retiro
          </div>
          <mmax-turnos-retiro (turnoSelecionado)="turnoselect($event)"></mmax-turnos-retiro>
        </div>

        <div>
          <div class="productoslabel separator">
            Quién retira el pedido?
          </div>
          <div class="infobox" *ngIf="user != undefined">
            <div class="info infoblue">
              {{user.nombre}}
            </div>
          </div>
        </div>
        <div>
          <div class="productoslabel">
            Observaciones
          </div>
          <div class="boxtext">
            <textarea (change)="observaCion()" id="obs" class="boxobs" placeholder="Escribe aquí las aclaraciones sobre tu pedido…">
            </textarea>
          </div>
        </div>

        <div class="total">
          Total: {{totales | currency}}
        </div>
        <div class="botncontainer">
          <div class="noselect btons conticompra" style="width: 40%;" (click)="iraLand()" onclick="window.scrollTo(0,0)" >
            Anterior
          </div>
          <button class="noselect buttonpc blubutton" style="width: 40%;" [disabled]="usaTurnos == 'S' && turnoselecionado==undefined" (click)="modalCheckout()" onclick="window.scrollTo(0,0)" >
            Siguiente
          </button>
        </div>
      </div>

    </div>

  </div>
  <mmax-helpfootbar></mmax-helpfootbar>
</div>
<ng-template #noUsaTurnos>
  <div class="productoslabel separator ">
    Tiempo de Preparación
  </div>
  <div class="infobox">
    <div class="info infoblue">
      El pedido va a estar en {{horasMinimasTurno}} horas.
    </div>
  </div>
</ng-template>

<ng-template #NotSuc>
  <div class="infobox">
    <div class="info infoblue">
      No tiene sucursal seleccionada. Por favor, seleccione una.
      <button class="noselect btons conticompra" style="display:initial; width: auto; margin-left: 2em;"
        (click)="irASuc()">Ir a
        Sucursales</button>
    </div>
  </div>
</ng-template>
