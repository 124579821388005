import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticulosGeneral, EcommerceGenericoService, MediosPagos } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-cierre-carro',
  templateUrl: './mmax-cierre-carro.component.html',
  styleUrls: ['./mmax-cierre-carro.component.scss']
})

export class MmaxCierreCarroComponent implements OnInit {
  displayPreMedios: boolean = false;
  displayMedios: boolean = false;
  displayMP: boolean = false;
  displayPago: boolean = false;
  error: string;
  planesDePagoEnPantalla: PagosMMaxPlanes[] = [];
  mymedios: MediosPagos;
  myPlan: PagosMMaxPlanes;
  mercadoP: boolean = false;
  pagoDiferido: string;
  ruta: string;
  flagDeshabilitado: boolean = false;
  fueCargadoPreviamenteBotonMp: boolean = false; //el boton de mp solo se puede cargar una sola vez, por eso se oculta en vez de usar un ngif
  beneficiosTrue: boolean = false;
  saldoActual: number = 0;
  conSaldo: boolean = false;
  errorSaldo: boolean = false;
  cargandoPlanes : boolean = false;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute) {
    this.genServ.getPagoDiferido().subscribe({
      next: pagoDiferido =>{
        this.pagoDiferido = pagoDiferido
      }
    })
    this.ruta = window.location.hash;
    this.genServ.returnBeneficios().subscribe({
      next:data => {
        if (data == 'S') {
          this.beneficiosTrue = true;
          this.genServ.getMovimientosMmax().subscribe({
            next:data => {
              this.saldoActual = data[0].saldo_real;
              this.conSaldo = true;
            },
            error:error => {
              this.errorSaldo = true;
              this.genServ.pendingFalse();
            }
          })
        }
      }
    })
  }

  ngOnInit(): void {
    window.addEventListener("beforeunload", this.beforeUnload);
    this.deshabilitarBack();

    if (this.pagoDiferido == "S") {
      this.displayMedios = false;
      this.displayPreMedios = true;
    }

    if (this.pagoDiferido == "N") {
      this.displayMedios = true;
      this.displayPreMedios = false;
    }
  }

  get mysmedios() {
    return this.genServ.devolverMediosPagos() || [];
  }

  get totales() {
    let montoTotal = this.genServ.getTotal();
    //montoTotal += this.genServ.costoUltimoFlete;
    return montoTotal;
  }

  get costoEnvio() {
    return this.genServ.costoUltimoFlete;
  }

  get total() {
    let cantTotal = this.genServ.getCantidadTotal();
    const articulosEnCarrito : ArticulosGeneral[] = this.genServ.getCarrito();
    //console.log("articulos", articulosEnCarrito);

    articulosEnCarrito.forEach(
      (articulo : ArticulosGeneral) => {
        if (articulo.getNombreMarca() == 'FLETES') {
          cantTotal = cantTotal + 1;
        }
      }
    );
    return cantTotal;
  }

  get articulosconcantidad() {
    return this.genServ.getCarrito();
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  beforeUnload(e){
    var confirmationMessage = "LOL";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  deshabilitarBack() {
    window.onhashchange = function () {
      if (window.location.hash != "#/checkout/finalizar") {
        this.flagDeshabilitado = true;
        window.location.hash = "#/checkout/finalizar";
      }
      return this.flagDeshabilitado;
    }

    if (this.flagDeshabilitado === true) {
      this.serv.navigator.cierreCheckout(this.router);

    }
  }

  habilitarBack(){
    window.removeEventListener("beforeunload", this.beforeUnload);
  }

  volverAtras() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.genServ.resetEccomerce();
    this.serv.navigator.goLandPage(this.router);
    this.serv.cerrarUI();
  }

  siPagar() {
    this.displayMedios = true;
    this.displayPreMedios = false;
  }

  noPagar() {
    this.serv.navigator.endline(this.router);
    this.genServ.resetEccomerce();
  }

  activarMediodepago(medio: MediosPagos) {
    this.displayPago = true;
    this.displayMP = false;
    this.genServ.devolverMediosPagos().forEach(
      (medioDePago : MediosPagos) => {
        medioDePago.selecionado = false;
      }
    )
    this.mercadoP = false;
    medio.selecionado = true;
    this.mymedios = medio;
    //console.log('this.mymedios.mediopago', this.mymedios.mediopago)
    this.cargandoPlanes = true;
    this.genServ.obtenerPlanesdemedios(this.mymedios.mediopago).subscribe({
      next:respuestaPlanesDeMedios => {
        this.cargandoPlanes = false;
        this.planesDePagoEnPantalla = [];
        const planes : PagosMMaxPlanes[] = respuestaPlanesDeMedios['planes'];
        if(planes !== undefined) {
          planes.forEach(
            (planDePago : PagosMMaxPlanes) => {
              console.log('activarMediodepago', planDePago)
              //idcheckout solo llega en el medio de MercadoPago, en los otros no llega
              if (planDePago.idcheckout !== undefined) {
                console.log('activarmediodepago mp', planDePago.idcheckout)
                this.activarMercadoPago(planDePago.idcheckout)
              }
              this.planesDePagoEnPantalla.push(
                {
                  cuotas: planDePago?.cuotas,
                  montoCuota: planDePago?.montoCuota,
                  nombre: planDePago?.nombre,
                  plan: planDePago?.plan,
                  id: this.genServ.generarid(5),
                  seleccionado: false,
                }
              )
              console.log('activar medio planesDePAgoEnpantalla',this.planesDePagoEnPantalla)
            }
          );
          if (this.planesDePagoEnPantalla[0] != undefined) {
            this.planesDePagoEnPantalla[0].seleccionado = true;
          }
          this.myPlan = this.planesDePagoEnPantalla[0];
          if (this.myPlan != undefined) {
            this.myPlan.seleccionado = true
          }
        }
        //console.log('planes de pago', this.planesDePagoEnPantalla)
      }
    });

  }

  activarMercadoPago(idcheckoutMercadoPago : string) {
    this.habilitarBack();
    let publicKey = this.genServ.getPublicKeyMP();
    this.mercadoP = true;
    this.displayPago = false;
    this.displayMP = true;
    console.log('activarMercadoPago')
    console.log('total ', this.totales.toFixed(2))
    if (!this.fueCargadoPreviamenteBotonMp) {
      this.fueCargadoPreviamenteBotonMp = true;
      setTimeout(() => {
        const mp = new MercadoPago(publicKey, {
          locale: 'es-AR'
        });
        console.log(mp, mp.checkout)
        mp.checkout({
          preference: {
            id: idcheckoutMercadoPago
          },
          render: {
            container: '#containerMP',
            label: 'Pagar $' + this.totales.toFixed(2),
            type: 'wallet',
          }
        });
      }, 1000);
    }
  }

  activarPlan(plan: PagosMMaxPlanes) {
    this.planesDePagoEnPantalla.forEach(k => {

      if (plan != k) {
        k.seleccionado = false;
      }
    })
    plan.seleccionado = true;
    this.myPlan = plan;

  }

  pagarYcontinuar() {
    this.error = undefined;
    this.genServ.cerrarFinaleccomerce(this.mymedios.mediopago, this.myPlan.plan).subscribe(data => {
      this.serv.navigator.endline(this.router);
      this.genServ.resetEccomerce();
    }, err => {

      this.error = err['error']['statussalida'];
    })
  }
}
//mercadopago checkout pro integration, ver doc https://www.mercadopago.com.ar/developers/es/docs/checkout-pro/integrate-checkout-pro/web
declare var MercadoPago: any;

interface PagosMMaxPlanes {
  cuotas: number,
  montoCuota: number,
  nombre: string,
  plan: number,
  id?: string,
  seleccionado?: boolean,
  idcheckout?: string,
}
