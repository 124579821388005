import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, EcommerceGenericoService, RemplazosMMax, } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria,} from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'productos-view',
  templateUrl: './productos-view.component.html',
  styleUrls: ['./productos-view.component.scss'],
})
export class ProductosViewComponent implements OnInit {
  @Input() articulo: ArticulosGeneral;
  @Input() mode: string;
  @Output() closeGap: EventEmitter<boolean>;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.width = window.innerWidth;
  }

  constructor( private serv: MarianomGeneral,private route: ActivatedRoute, private modal: ModalService, private genServ : EcommerceGenericoService) {
    this.mode = this.mode || 'normal';
    this.closeGap = new EventEmitter();
  }

  async ngOnInit(): Promise<void> {
    if(this.mode === 'detalletrakin'){
      console.log(this.articulo)
    }
    this.width = window.innerWidth
    if (!this.articulo.getImgDownload()) {
      await this.genServ.bajarImagen(this.articulo).toPromise().then(
        () => {
          this.articulo.setImgDownload(true);
        }
      );
    }
    this.genServ.getReemplazosProvider().subscribe(
      data => {
        this.misremplasos = data;
      }
    );
  }

  iraSeach() {
    this.closeGap.emit(true);
  }

  iraSeachmobile() {
    this.serv.navigator.goSearch(this.route);
  }

  categoriaSelec(reemplazoModificado: any) {
    console.log('categoriaSelec', reemplazoModificado, this.articulo.getRemplazos());
    this.articulo.getRemplazos().forEach(
      reemplazoObj => {
        //console.log('reemplazoobj', reemplazoObj.idcss, reemplazoModificado[0].idcss,reemplazoModificado[0].idcss === reemplazoObj.idcss )
        if( reemplazoObj.idcss === reemplazoModificado[0].idcss ){
          //console.log('encontro')
          reemplazoObj.activo = reemplazoModificado[0].activo
        }
      }
    );
    //console.log('categoria select despues', event, this.articulo.getRemplazos());
  }

  eliminar() {
    this.genServ.eliminarPromos(this.articulo);
    this.genServ.sincronizarCarritoFrontyBack();
  }

  sumaevent(numero: number) {
   console.log('sumaevent', numero)
    if ((!this.serv.isloged() || localStorage.getItem('currentUser') == null || localStorage.getItem('currentUser') == undefined) && !this.genServ.sePermiteAgregarArticuloACarritoSinLogin) {
      this.serv.abrirModalLogin();
      return
    }
    if (numero > 0) {
      if (this.articulo.getCantidad() == 0) {
        this.articulo.agregarCantidad(numero);
        this.serv.articuloAmostrar(this.articulo);

      }
      else {
        if(this.articulo.getCantidadMaxima() > this.articulo.getCantidad() ||numero < 0 ||this.articulo.getCantidadMaxima() == 0 ||(this.articulo.getCantidadMaxima() > numero && numero != 1)) {
          if(this.articulo.getCantidadMaxima() > numero ||this.articulo.getCantidadMaxima() == 0) {
            this.articulo.agregarCantidad(numero);
            this.serv.articuloAmostrar(this.articulo);
          }
          else {
            this.modal.open('modal-max-art');
          }
        }
        else {
          this.modal.open('modal-max-art');
        }
      }
    }
    else {
      if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || (this.articulo.getCantidadMaxima() > numero && numero != 1)) {
        if (this.articulo.getCantidad() + numero >= 0 || this.articulo.getCantidadMaxima() > numero) {
          this.articulo.agregarCantidad(numero);
        }
        else {
          this.modal.open('modal-max-art');
        }
      }
      else {
        this.modal.open('modal-max-art');
      }
    }
    this.genServ.sincronizarCarritoFrontyBack();
    this.articulo.operacionesExtras();
  }

  openArtModal() {
    this.modal.open('modal-articulo');
  }

  artOpened(art : ArticulosGeneral) {
    console.log(art);
    this.genServ.setearArticuloParaModal(art);
  }

  obtenerTipoUnidadDeEtiquetaDeArticulo(articulo: ArticulosGeneral) {
    if (articulo.getOtrosPrecios().length === 0) {
      return 'Un';
    }
    let unidad: string = articulo.getOtrosPrecios().slice(articulo.getOtrosPrecios().indexOf('x'));
  }
  consolelog(asd){
    console.log(asd)
  }
  misremplasos: RemplazosMMax[];
  remplazoselct: RemplazosMMax;
  gaseosas: CheckboxCategorias[];
  preload: boolean;
  width;
}
