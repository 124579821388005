<modal-open-point id="modal-completar-pago">
  <div normal>
    <modal-completar-pago></modal-completar-pago>
  </div>
</modal-open-point>

  <div class="container">
    <div class="productos">
      <div class="productoslabel">
        Productos ({{total}})
      </div>
      <div class="listaprod">
        <lista-productos-view [articulosIn]="articulosconcantidad" [modelo]="'postcheckout'">
        </lista-productos-view>
      </div>
    </div>

    <div class="checkoutcontainer" [ngStyle]="{'flex-direction': (displayMedios && (displayMP || displayPago)) ? 'column' : 'row'}" >
      <div *ngIf="displayPreMedios" class="preMedios">
        <div class="productoslabel">
          Desea pagar ahora el pedido?
          Si elige que NO, se tomara como un Pago Diferido, el cual se debera abonar o en la sucursal cuando
          se retire o si se dispone de envios, cuando llegue el mismo.
        </div>
        <div class="infobox">
          <div class="pagarAhoraODiferido" >
            <button class="buttonpc blubutton"  (click)="siPagar()">
              Pagar ahora
            </button>
            <button class="buttonpc redbutton" (click)="noPagar()">
              Pago Diferido
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="displayMedios" class="mediosPago" >
        <div *ngIf="mysmedios.length>0; else nomedios">
          <div class="productoslabel">
            Forma de Pago
          </div>
          <div class="infobox" style="flex-direction: column;">
            <div *ngFor="let medio of mysmedios" class="infofix ">
              <div class="infoflex" style="width: 100%;">
                <div class="info">
                  <div class="roundedOneR"
                    style="flex-direction: row; display: flex; align-items: center; margin: 20px; margin-left: 30px;">
                    <input (click)="activarMediodepago(medio)" [checked]="medio.selecionado" [id]="medio.id"
                      type="checkbox" />
                    <label [for]="medio.id"></label>
                  </div>
                </div>
                <div class="info"
                  style="display: flex; width: 100%; justify-content: space-between; align-items: center;">
                  <label style="margin-left: 20px; font-size: 22px;" [for]="medio.id">{{medio.nombre}}</label>
                  <div *ngIf="beneficiosTrue && medio.fiid == 'DLR'" style="display: flex;">
                    <label class="saldoInfo" for="saldoActual" *ngIf="conSaldo; else cargandoSaldo">
                      Saldo Actual:
                      $<b>{{saldoActual}}</b>
                    </label>
                    <ng-template #cargandoSaldo>
                      <label *ngIf="!errorSaldo; else saldoError" class="saldoInfo" for="saldoActual">
                        Saldo Actual:
                        Cargando...
                      </label>
                    </ng-template>
                    <ng-template #saldoError>
                      <label class="saldoInfo" for="saldoActual">
                        Saldo Actual:
                        Error al pedir saldo!
                      </label>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #nomedios>
          <div class="checkoutcontainer">
            <div class="productoslabel">
              Forma de Pago
            </div>
            <div class="infobox">
              <div class="infofix">
                <div class="info">
                  No dispone de medios asociados a su cuenta para abonar.
                </div>
                <div class="info">
                  <button (click)="volverAtras()" class="buttonpc whibutton noselect">Volver a
                    Home</button>
                </div>
              </div>
            </div>
          </div>

        </ng-template>

        <div *ngIf="mymedios!==undefined">
          <div class="plan" *ngIf="planesDePagoEnPantalla.length>0; else noPlanes">
            <div class="productoslabel">
              Planes de Pago {{mymedios.nombre}}
              <div *ngIf="error!=undefined" class=" info tooltip">
                <div class="errorus">
                  {{error}}
                  <div class="atras">
                    <button (click)="volverAtras()" class="buttonpc whibutton noselect">Volver a
                      Home</button>
                  </div>
                </div>

              </div>
            </div>

            <div class="infobox">
              <div *ngFor="let plan of planesDePagoEnPantalla" (click)="activarPlan(plan)" class="infofix">
                <div class="infoflex">
                  <div class="info">
                    <div class="roundedOne">
                      <input [checked]="plan.seleccionado" [id]="plan.id" type="checkbox" />
                      <label [for]="plan.id"></label>
                    </div>
                  </div>

                  <div class="info">
                    {{plan.cuotas}}x {{plan.montoCuota | currency}} - {{plan.nombre}}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <ng-template #noPlanes>
            <div class="productoslabel">
              Planes de Pago {{mymedios.nombre}}
            </div>
            <div class="infobox">
              <div class="infofix">
                <spinner *ngIf="cargandoPlanes" style="margin: auto;" ></spinner>
                <ng-container *ngIf="!cargandoPlanes">
                  <div class="info">
                    No dispone de planes asociados al metodo de pago selecionado.
                  </div>
                  <div class="info">
                    <button (click)="volverAtras()" class="buttonpc whibutton noselect">Volver a
                      Home</button>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="mercadoPago" [ngStyle]="{'display': displayMP ? 'block' : 'none' }" >
        <div class="botoneraPC">
          <div class="sutotalesbox">
            <div class="renglon">
              <div class="infoblue subtotaltext">
                Subtotal
              </div>
              <div class="infoblue textcomun centertext">
                {{totales - costoEnvio | currency}}
              </div>
            </div>
            <div class="renglon">
              <div class="infoblue textcomun">
                Costo de envío
              </div>
              <div class="infoblue textcomun centertext eltotal">
                {{costoEnvio | currency}}
              </div>
            </div>

            <!-- <div class="renglon">
              <div class="infoblue textcomun">
                Descuentos Aplicados
              </div>
              <div class="infoblue textcomun centertext">
                {{0 | currency}}
              </div>
            </div> -->
            <div class="renglon">
              <div class="infoblue subtotaltext">
                TOTAL:
              </div>
              <div class="cho-container" id="containerMP"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="displayPago" class="botoneraPC">
        <div class="sutotalesbox">
          <div class="renglon">
            <div class="infoblue subtotaltext">
              Subtotal
            </div>
            <div class="infoblue textcomun centertext">
              {{totales - costoEnvio | currency}}
            </div>
          </div>
          <div class="renglon">
            <div class="infoblue textcomun">
              Costo de envío
            </div>
            <div class="infoblue textcomun centertext eltotal">
              {{costoEnvio | currency}}
            </div>
          </div>

          <!-- <div class="renglon">
            <div class="infoblue subtotaltext">

            </div>
            <div class="infoblue textcomun centertext">
              {{totales | currency}}
            </div>
          </div> -->



          <!-- <div class="renglon">
            <div class="infoblue textcomun">
              Descuentos Aplicados
            </div>
            <div class="infoblue textcomun centertext">
             {{0 | currency}}
            </div>
          </div> -->
          <div class="renglon">
            <div class="infoblue subtotaltext">
              TOTAL:
            </div>
            <button class="buttonpc blubutton noselect"  onclick="window.scrollTo(0,0)" (click)="pagarYcontinuar()" [disabled]="(myPlan==undefined || mymedios==undefined)">
              Pagar {{totales | currency}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mmax-helpfootbar></mmax-helpfootbar>
