<section>
  <div class="formsup">
    <div class="titulosop">
      Ciudad
    </div>
    <!-- <input type="text" id="ciudad" class="inputSup" placeholder="Ingrese su ciudad de residencia" (change)="datosEnvio()"> -->
    <div class="selectedCiudad" >
      <div class="selectorCiudad" *ngIf="!error; else notCitys">
        <selector-generico *ngFor="let ciudad of ciudades" (onSelect)="ciudadSeleccionada($event)" [mode]="'selector'"
          [catego]="ciudad" [defaultSelect]="ciudadElegida"></selector-generico>
      </div>
      <div class="datosCiudad" [ngStyle]="{'display': mostrarCosto ? 'flex' : 'none'}">
        <div class="titulosop">
          Costo de Envio:
          <b *ngIf="costo>0" style="text-indent: 0.5em">
            ${{costo}}
          </b>
          <b *ngIf="costo===0" style="text-indent: 0.5em;text-decoration: underline;">
            Envío Gratis
          </b>
        </div>
        <div class="titulosop">
          Codigo Postal: <b style="text-indent: 0.5em">{{codigoPostal}}</b>
        </div>
      </div>
    </div>
    <form class="form" [formGroup]="formEnvio">
      <div class="formFieldContainer" >
        <div class="titulosop">
          Barrio
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su barrio de residencia" [formControlName]="'barrio'" (input)="datosEnvio()">
      </div>
      <div class="formFieldContainer">
        <div class="titulosop">
          Calle
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su calle de residencia" [formControlName]="'calle'" (input)="datosEnvio()">
      </div>
      <div class="formFieldContainer" >
        <div class="titulosop">
          Número
        </div>
        <input type="text" class="inputSup" placeholder="Ingrese su número/altura de calle" [formControlName]="'numero'" (input)="datosEnvio()">
      </div>
    </form>
  </div>
</section>

<ng-template #notCitys>
  <div style="font-size: 20px;">
    No hay ciudades disponibles.
  </div>
</ng-template>
