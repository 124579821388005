<div normal>
  <modal-default-mmaxapp>
    <div class="tarjetaconfirmar" *ngIf="envio == false || envioForm == true; else formEmpty">
      <div class="titulo">
        Cerrar pedido
      </div>
      <div class="leyendaconfirmar">
        Esta acción te re-direcciona al cierre del pedido. Le informamos que no va a poder
        agregar mas productos a este pedido o regresar a cambiar los turnos una vez usted
        confirme esta accion. ¿Deseas continuar con el cierre del pedido?

      </div>
      <div class="botonesCierreCarrito">
        <button class="btnCierreCarrito whibutton" (click)="cancelar()">
          Cancelar
        </button>
        <button class="btnCierreCarrito redbutton" (click)="cerrarPedido()">
          Aceptar
        </button>
      </div>
    </div>
    <ng-template #formEmpty>
      <div class="tarjetaconfirmar">
        <div class="titulo">
          Complete el formulario de envio para continuar.
        </div>
        <div class="botonesCierreCarrito">
          <button class="btnCierreCarrito redbutton " (click)="cancelar()">
            Aceptar
          </button>
        </div>
      </div>
    </ng-template>
  </modal-default-mmaxapp>
</div>
