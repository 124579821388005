import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService, PedidosMmaxEccomerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'confirmar-checkout-modal',
  templateUrl: './confirmar-checkout-modal.component.html',
  styleUrls: ['./confirmar-checkout-modal.component.scss']
})

export class ConfirmarCheckoutModalComponent implements OnInit {
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.genServ.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data;
    })
    this.checkForm = false;
  }

  get envioForm() {
    let dataEnvio = this.genServ.getDatosEnvio()
    if (dataEnvio != undefined) {
      if (dataEnvio.ciudad.length != 0 && dataEnvio.barrio.length != 0 && dataEnvio.calle.length != 0 && dataEnvio.numero.length != 0) {
        this.checkForm = true;
      } else {
        this.checkForm = false;
      }
    }
    return this.checkForm;
  }

  ngOnInit(): void {
  }

  cerrarPedido() {
    this.turnoselecionado = this.serv.ultimoTurno();
    this.serv.setTurno(this.turnoselecionado);
    if (this.observacion) {
      this.genServ.setObservacion(this.observacion)
    }

    if(this.serv.isEnvio()){
      this.genServ.agregarItemEnvio();
    }

    this.modal.close('confirmar-checkout-modal-1');

    this.serv.prayandpaid().subscribe(data => {
      let pedido = data['pedido'] as PedidosMmaxEccomerce;
      this.genServ.guardarpedido(pedido);
      //this.serv.setEnvio(false);
      //this.genServ.setDatosEnvio(undefined)
      if (this.pagoDiferido === "E") {
        this.serv.navigator.endline(this.router);
        this.genServ.resetEccomerce();
      }
      else {
        this.serv.navigator.cierreCheckout(this.router);
      }
    });
  }

  cancelar() {
    this.modal.close('confirmar-checkout-modal-1')
  }

  get envio() {
    return this.serv.isEnvio();
  }

  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  envioHabilitado: string;
  pagoDiferido: string;
  checkForm: boolean;
}
