<div *ngIf="modo==='normal'">
    <div class="soporte">
        <div class="suptext">
            <div class="contactsup">
                Contacto de Atención al Cliente
            </div>
            <div style="margin-top: 20px;" class="contactsup">
                {{mailAtencionAlCliente}}
            </div>
        </div>
        <div class="splash noselect">
            <img class="img-splash" [src]="soporteimg">
        </div>

    </div>
</div>
<div *ngIf="modo==='flotante'">
    <div class="flotanteconainter">
        <div class="soporte">
            <div class="suptext">
                <div class="contactsup">
                    Contacto de Atención al Cliente
                </div>
                <div class="contactsup">
                    {{mailAtencionAlCliente}}
                </div>
            </div>
            <div class="splash noselect">
                <img class="img-splash" [src]="soporteimg">
            </div>
            <div class="arrepentimiento">
                <button class="btn-arr" (click)="arrepentimiento()">Boton de Arrepentimiento</button>
            </div>

        </div>
    </div>

</div>
