import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { Ciudad } from 'projects/ecommerce-generico/src/lib/interfaces/ciudades-envío.interface';
import { DatosEnvio } from 'projects/ecommerce-generico/src/lib/interfaces/envios.interface'

@Component({
  selector: 'app-envios-form',
  templateUrl: './envios-form.component.html',
  styleUrls: ['./envios-form.component.scss']
})

export class EnviosFormComponent implements OnInit {
  @Output() envioSeleccionado: EventEmitter<DatosEnvio> = new EventEmitter();

  formEnvio: FormGroup;
  ciudades: any[];
  ciudadesArray: any[] = [];
  costoEnvio: number;
  error: boolean;
  ciudadElegida : string;
  mostrarCosto : boolean = false;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private formBuilder: FormBuilder) {
    this.error = false;
    this.ciudades = [
      {
        nombre: 'Cargando ciudades...',
        categorias: []
      }
    ];
  }

  ngOnInit(): void {
    this.genServ.getCiudades().subscribe({
      next:data => {
        if (data['registros'].length != 0) {
          //console.log('ciudades',data)
          data['registros'].forEach(
            ciudad =>{
              let nuevaCiudad : Ciudad = {
                ciudad: ciudad.ciudad,
                nombre: ciudad.nombre.trim(),
                cp: ciudad.cp,
                costo: ciudad.costo>=0 ? ciudad.costo : 0
              }
              this.ciudadesArray.push(nuevaCiudad);
              //console.log('user',this.genServ.getUserSync(), this.genServ.getUserSync().ciudad)
              if(ciudad.ciudad === this.genServ.getUser().ciudad){
                this.ciudadElegida = nuevaCiudad.nombre;
              }
            }
          )
          this.prepararCiudades();
          //console.log('this.ciudades',this.ciudades[0].categorias, this.ciudadElegida)
          this.ciudades[0].categorias.forEach(
            ciudadSelectOption =>{
              console.log('ciudadSelectOption',ciudadSelectOption, this.ciudadElegida)
              if(ciudadSelectOption.nombre === this.ciudadElegida){
                this.ciudadElegida = ciudadSelectOption.nombre;
                this.serv.setSelectorName(this.ciudadElegida);
                this.ciudadSeleccionada(ciudadSelectOption);
              }
            }
          )
        }

      },
      error:err => {
        this.error = true;
      }
    });

    this.formEnvio = this.formBuilder.group({
      barrio: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      nombreCiudad: ['', [Validators.required]],
      costo: ['', [Validators.required]],
      cp: ['', [Validators.required]],
    });
  }

  get ciudad(){
    return this.formEnvio.value.ciudad
  }

  get codigoPostal(){
    return this.formEnvio.value.cp
  }
  get costo(){
    return this.formEnvio.value.costo
  }

  prepararCiudades() {
    let i = 0;
    this.ciudades = [
      {
        nombre: 'Elegir ciudad',
        categorias: []
      }
    ];
    this.ciudadesArray.forEach(k => {
      let ciudadDisponible: CheckboxSubcategoria = {
        nombre: '',
        activada: false,
        id: '',
      }
      ciudadDisponible.nombre = k.nombre;
      ciudadDisponible.id = (i).toString();
/*       if(k.ciudad === this.genServ.getUserSync().ciudad){
        this.ciudadElegida = k.nombre;
        this.costoEnvio = k.costo;
        this.codigoPostal = k.cp;
        this.idCiudad = k.ciudad;
        this.costoTrue = 'flex';
        this.datosEnvio()
      } */
      this.ciudades[0].categorias.push(ciudadDisponible);
      i++;
    });

  }

  ciudadSeleccionada(filtro: CheckboxSubcategoria) {
    console.log(filtro)
    this.formEnvio.reset()
    this.ciudades[0].categorias.forEach(k => {
      k.activada = false;
    });
    this.ciudades[0].nombre = filtro.nombre;
    this.formEnvio.patchValue({nombreCiudad : filtro.nombre})

    filtro.activada = true;

    this.ciudadesArray.forEach(
      ciudadEnvio => {
        if (this.ciudades[0].nombre == ciudadEnvio.nombre) {
          this.formEnvio.patchValue({ciudad : ciudadEnvio.ciudad})
          this.formEnvio.patchValue({cp : ciudadEnvio.cp})
          this.formEnvio.patchValue({costo : ciudadEnvio.costo})
          this.mostrarCosto = true;
        }
      }
    );
    this.ciudadElegida = filtro.nombre;
    this.datosEnvio();
  }

  datosEnvio() {
    console.log(this.formEnvio.value)
    if(!this.formEnvio.valid ){
      return
    }
    console.log(this.formEnvio.value)
    this.envioSeleccionado.emit(this.formEnvio.value)
    //this.serv.setDatosEnvio(envio);
  }
}
