import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'restore-pass-mmax',
  templateUrl: './restore-pass-mmax.component.html',
  styleUrls: ['./restore-pass-mmax.component.scss']
})

export class RestorePassMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() form: string;

  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.modo = this.modo || 'normal';
    this.loader = this.loader || 'none';
    this.form = this.form || 'block';

  }

  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    new Image().src = this.imagen;
    this.resetform = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpass: ['', [Validators.required, Validators.minLength(8)]],
      resetid: ['', [Validators.required, Validators.minLength(11)]]
    });

  }

  guionador() {
    var inputValue = document.getElementById('token');

    inputValue.addEventListener('input', (e) => {
      var tokenValue = (e.target as HTMLInputElement).value,
        completedToken = tokenValue.replace(/^(\w\w\w)(\w)$/g, '$1-$2').replace(/^(\w\w\w\-\w\w\w)(\w+)$/g, '$1-$2').replace(/[^\w\-]/g, '');
      (e.target as HTMLInputElement).value = completedToken;
    }, false);
  }

  async cambiarPassword() {
    await this.genServ.restorePassMmax(this.formFields.username.value, this.formFields.password.value, this.formFields.resetid.value).toPromise().catch(err => {
      this.modal.open('modal-alert-pass');
      console.log(err);
      this.errorForm = err.error.statussalida;
      console.error(err['statusText']);

    });

    this.modal.close('restore-pass-modal');
    this.modal.open('login-modal-1');
  }


  get formFields() {
    return this.resetform.controls;
  }

  get userData() {
    return this.genServ.obtenerdatosdeUsuario();
  }

  imagen: string;
  resetform: FormGroup;
  createForm: FormGroup;
  errorForm: string;

}
