import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticulosEcommerce, ArticulosGeneral, EcommerceGenericoService, SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { PedidosMmaxEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { timeStamp } from 'console';
import { ModalService } from 'projects/app-shared/src/public-api';
import { DatosEnvio } from 'projects/ecommerce-generico/src/lib/interfaces/envios.interface';

@Component({
  selector: 'app-mmax-checkout-compra',
  templateUrl: './mmax-checkout-compra.component.html',
  styleUrls: ['./mmax-checkout-compra.component.scss']
})

export class MmaxCheckoutCompraComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() envioHab: string;

  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  user: Usuario;
  sucusarl: SucursalEcommerce;
  desplegado: boolean;
  envioHabilitado: string;
  pagoDiferido: string;
  isOn1: boolean;
  isOn2: boolean;
  //isEnvio: boolean;
  usaTurnos: string;
  horasMinimasTurno: string;
  sucTrue: boolean;
  // costoFlete : number;
  costoEnvio: number;
  displayEnvio : boolean = false;
  displayRetiro : boolean = false;
  constructor(private router: ActivatedRoute, private serv: MarianomGeneral, private modal: ModalService, private genServ : EcommerceGenericoService) {
    this.modo = this.modo || 'normal';
    this.envioHab = this.envioHab || 'none';
    this.desplegado = false;
    this.sucTrue = true;
    this.costoEnvio = 0;
    this.genServ.getUsuarioProvider().subscribe(data => {
      this.user = data;
    });
    this.genServ.getActualSucursal().subscribe(sucs => {
      this.genServ.getUsuarioProvider().subscribe(data => {
        if (data.sucursal && sucs.sucursal) {
          this.sucusarl = sucs;
        } else {
          this.sucTrue = false;
        }
      })
    });
    this.genServ.getEnvioHabilitado().subscribe(data => {
      this.envioHabilitado = data
      if (this.envioHabilitado === "S") {
        this.envioHab = 'flex';
      }
      else {
        this.envioHab = 'none';
      }
    });
    this.genServ.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data
    });
    this.genServ.returnUsaTurnos().subscribe(data => {
      this.usaTurnos = data;
    });
    this.genServ.returnHorasMinimasTurno().subscribe(data => {
      this.horasMinimasTurno = data;
    })
  }

  ngOnInit(): void {
    this.observacion = undefined;
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }

  get articulosconcantidad() {
    return this.genServ.getCarrito();
    /*
    let articulosSinFlete : ArticulosGeneral[] = this.genServ.getCarrito().filter(
      articuloEnCarro => {
        return articuloEnCarro.getMarca() !== parseInt(this.genServ.marcaFlete) ||
               articuloEnCarro.getCodigo() !== parseInt(this.genServ.codigoFlete)
// /*         if(articuloEnCarro.getMarca() !== parseInt(this.genServ.marcaFlete) || articuloEnCarro.getCodigo() !== parseInt(this.genServ.codigoFlete)){
//           return true
//         }
//         else {
//           this.costoFlete = articuloEnCarro.getPrecio()
//           return false;

//         }
//       }
    )
    return articulosSinFlete;*/
  }

  get totales() {
    return this.genServ.getTotal();
  }

  get total() {
    return this.genServ.getCantidadTotal();
  }

  setRetiroEnvio(modo: number) {
    this.serv.setRetiroEnvio(modo);
  }

  turnoselect(turnoselect) {
    this.turnoselecionado = turnoselect;
    this.serv.setTurno(this.turnoselecionado);
  }

  setupExclusiveCheckboxes(checkbox1Id: string, checkbox2Id: string) {
    const checkbox1 = document.getElementById(checkbox1Id) as HTMLInputElement;
    const checkbox2 = document.getElementById(checkbox2Id) as HTMLInputElement;

    checkbox1.addEventListener('change', (event) => {

      const target = event.target as HTMLInputElement;
      console.log(target)

      if (this.isOn1) {
        checkbox1.checked = true;
      }
      if (target.checked) {
        checkbox2.checked = false;
        this.isOn2 = false;
        this.isOn1 = true;
      }
    });

    checkbox2.addEventListener('change', (event) => {

      const target = event.target as HTMLInputElement;
      if (this.isOn2) {
        checkbox2.checked = true;
      }
      if (target.checked) {
        checkbox1.checked = false;
        this.isOn1 = false;
        this.isOn2 = true;
      }
    });
  }

  envio() {
    this.displayRetiro = false;
    this.displayEnvio = true;
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro');
    this.serv.setEnvio(true);
  }

  retiro() {
    this.displayEnvio = false;
    this.displayRetiro = true;
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro');

    this.serv.setEnvio(false);
    this.serv.setDatosEnvio(undefined);
    //console.log('this.genServ.costoUltimoFlete', this.genServ.costoUltimoFlete)
    this.costoEnvio=0;
    this.genServ.costoUltimoFlete = 0;
  }

  abrirTurnos() {
    this.serv.abrirTurnos()
  }

  seEligioDatosEnvio( datosEnvio : DatosEnvio){
    console.log('seEligioDatosEnvio', datosEnvio);
    this.serv.setDatosEnvio(datosEnvio)
    this.costoEnvio=datosEnvio.costo;
  }

  cerrarPedido() {
    this.serv.setTurno(this.turnoselecionado);
    this.observaCion();
    this.genServ.deletePromosCloud().subscribe((data) => {
      this.genServ.cerrarPromos().subscribe((data) => {
        this.serv.prayandpaid().subscribe(data => {
          let pedido = data['pedido'] as PedidosMmaxEccomerce;
          this.genServ.guardarpedido(pedido);
          //this.serv.setEnvio(false);
          //this.genServ.setDatosEnvio(undefined)
          if (this.pagoDiferido === "E") {
            this.serv.navigator.endline(this.router);
            this.genServ.resetEccomerce();
          }
          else {
            this.serv.navigator.cierreCheckout(this.router);
          }
        });
      });
    });
  }

  observaCion() {
    this.observacion = (<HTMLInputElement>document.getElementById("obs")).value;
    this.genServ.setObservacion(this.observacion);
  }

  cerrarPedidoMobile() {
    this.observaCion();
    this.serv.setTurno(this.turnoselecionado);
    this.serv.navigator.cierreCheckout(this.router)
  }

  iraLand() {
    this.serv.setEnvio(false);
    this.genServ.setDatosEnvio(undefined);
    this.serv.navigator.irResumenCompra(this.router)
    this.serv.cerrarUI();
  }

  modalCheckout() {
    this.modal.open('confirmar-checkout-modal-1');
  }

  irASuc() {
    this.serv.gotoURL('sucursales', this.router)
  }
}
